import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import { kindOfTeacherLessonsAtom, teacherLessonsAtom } from "./store";

const useMyLessonsPageApi = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const [kindOfTeacherLessons, setKindOfTeacherLessons] = useAtom(
    kindOfTeacherLessonsAtom
  );
  const [, setTeacherLessons] = useAtom(teacherLessonsAtom);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(currentUser.id.toString())
      .lessons,
    {
      query: { status: kindOfTeacherLessons },
      onSuccess: (data) => {
        setTeacherLessons(data);
      },
      onError: () => {
        addErrorMessage("授業一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfTeacherLessons, setKindOfTeacherLessons };
};

export default useMyLessonsPageApi;
