import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import useHeaders from "hooks/useHeaders";

import { useAlertMessages } from "hooks/useAlertMessages";
import { useHistory } from "react-router";
import { Methods } from "api/api/v1/teacher_profiles";
import { organizationCategories, prefectures, tags } from "./store";

const useNewPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const [, setPrefectures] = useAtom(prefectures);
  const [, setOrganizationCategories] = useAtom(organizationCategories);
  const [, setTags] = useAtom(tags);
  const history = useHistory();

  const { data } = useAspidaSWR(aspida(headers).api.v1.teacher_profiles.new, {
    onSuccess: (data) => {
      setPrefectures(data.prefectures);
      setOrganizationCategories(data.organization_categories);
      setTags(data.tags);
    },
    onError: () => {
      addErrorMessage("データの取得に失敗しました");
      history.push("/teachers/login");
    }
  });

  const postNewProfile = async (params: Methods["post"]["reqBody"]) => {
    // websiteのtitleとurlが空の場合は除外する
    const webSites = params?.websites?.filter(
      (website) => website.title !== "" && website.url !== ""
    );
    params.websites = webSites;

    try {
      const response = await aspida(headers).api.v1.teacher_profiles.post({
        body: params
      });
      if (response.status === 200) {
        history.push("/teachers/profile/complete");
      } else {
        addErrorMessage("データの送信に失敗しました");
      }
    } catch {
      addErrorMessage("データの送信に失敗しました");
    }
  };

  return { data, postNewProfile };
};

export default useNewPageApi;
