import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import {
  kindOfTeacherRecruitmentsAtom,
  teacherRecruitmentsAtom
} from "./store";

const useMyRecruitmentsPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const currentUser = useAtomValue(currentUserAtom);
  const [kindOfRecruitments, setKindOfRecruitments] = useAtom(
    kindOfTeacherRecruitmentsAtom
  );
  const [, setteacherRecruitments] = useAtom(teacherRecruitmentsAtom);
  const teacherId = currentUser.id.toString();

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId)
      .lesson_recruitments,
    {
      query: { status: kindOfRecruitments },
      onSuccess: (data) => {
        setteacherRecruitments(data);
      },
      onError: () => {
        addErrorMessage("募集一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfRecruitments, setKindOfRecruitments };
};

export default useMyRecruitmentsPageApi;
