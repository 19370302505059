import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import { teacherVideosAtom } from "store/TeacherVideosStore";
import type React from "react";
import IntroVideoField from "../molecules/IntroVideoField";
import IntroVideoPreview from "../molecules/IntroVideoPreview";
import ProfileEditContentsAccordion from "./ProfileEditContentsAccordion";

import { postTeacherVideo } from "../types/teacherVideoApiTypes";

type IntroVideosCardProps = {
  postTeacherVideo: postTeacherVideo;
  removeTeacherVideo: (videoId: number) => Promise<void>;
};

const IntroVideosCard: React.FC<IntroVideosCardProps> = (props) => {
  const { postTeacherVideo, removeTeacherVideo } = props;
  const teacherVideos = useAtomValue(teacherVideosAtom);
  const theme = useTheme();

  const videoKeys = ["intro1", "intro2", "intro3"];

  return (
    <ProfileEditContentsAccordion title="紹介動画" label="intro-videos">
      <Typography fontSize="0.8rem" color={theme.palette.sub.main}>
        ※アップした動画は、審査完了後に公開されます。
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        {videoKeys.map((key) => {
          const video = teacherVideos.find((video) => video.key === key);
          return (
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% / 3 - 1rem)" },
                flexGrow: 1
              }}
            >
              {video ? (
                <IntroVideoPreview
                  id={video.id}
                  url={video.file.url}
                  isPublic={video.is_public}
                  removeTeacherVideo={removeTeacherVideo}
                />
              ) : (
                <IntroVideoField
                  keyForApi={key}
                  postTeacherVideo={postTeacherVideo}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </ProfileEditContentsAccordion>
  );
};

export default IntroVideosCard;
