// yupのスキーマで繰り返し使用しそうなものを定義

import * as yup from "yup";
import dayjs from "libs/dayjs";

export const phoneNumberSchema = yup
  .string()
  .matches(/^[0-9]{10,11}$/, "正しい電話番号を入力してください");

export const katakanaSchema = yup
  .string()
  .test("katakana", "全角カタカナで入力してください。", (value) =>
    value ? /^[ァ-ヶー]+$/.test(value) : true
  );

export const passwordSchema = yup
  .string()
  .min(8, "8文字以上で入力してください")
  .matches(
    /^(?=.*?[a-zA-Z])(?=.*?\d)\S{8,}$/,
    "数字を含めた半角英数8文字以上で入力してください"
  );

// 口座番号 6~8桁の数字
export const accountNumberSchema = yup
  .string()
  .matches(/^\d{6,8}$/, "半角数字6~8桁で入力してください。");

// (例: 2000-8-1)
export const yearDateSchema = yup
  .string()
  .test("year-required", "年を入力してください", (value) => {
    if (!value) return false;
    const [year] = value.split("-").map(String);
    if (!year) return false;
    return true;
  })
  .test("month-required", "月を入力してください", (value) => {
    if (!value) return false;
    const [, month] = value.split("-").map(String);
    if (!month) return false;
    return true;
  })
  .test("day-required", "日を入力してください", (value) => {
    if (!value) return false;
    const [, , day] = value.split("-").map(String);
    if (!day) return false;
    return true;
  })
  .test("year-number", "年は半角数字で入力してください", (value) => {
    if (!value) return false;
    const [year] = value.split("-").map(Number);
    if (!year) return false;
    return true;
  })
  .test("month-number", "月は半角数字で入力してください", (value) => {
    if (!value) return false;
    const [, month] = value.split("-").map(Number);
    if (!month) return false;
    return true;
  })
  .test("day-number", "日は半角数字で入力してください", (value) => {
    if (!value) return false;
    const [, , day] = value.split("-").map(Number);
    if (!day) return false;
    return true;
  })
  .test("is-valid-date", "値の形式が正しくありません", (value) => {
    if (!value) return false;
    // 入力値をDateオブジェクトとして解析
    const date = dayjs(value);

    // 解析された日付が有効でなければfalseを返す
    if (!date.isValid()) {
      return false;
    }

    // 文字列から年、月、日を取得
    const [year, month, day] = value.split("-").map(Number);

    // Dateオブジェクトの年月日が入力値と一致しない場合は、存在しない日付（例: 2月30日）
    if (
      date.year() !== year ||
      date.month() + 1 !== month ||
      date.date() !== day
    ) {
      return false;
    }

    // 1850年以降の日付かどうかをチェック
    if (year < 1850) {
      return false;
    }

    // TODO: これは限定的なチェックなので、切り出したい
    // 現在の日付より前であるかをチェック
    const today = dayjs();
    today.startOf("day"); // 時間を00:00:00.000に設定
    if (date >= today) {
      return false;
    }

    return true;
  });

export const dateStringSchema = yup
  .string()
  .test("is-date", "有効な日付を入力してください", (value) => {
    if (!value) return false;
    const date = Date.parse(value);
    return !Number.isNaN(date);
  });

export const futureDateStringSchema = dateStringSchema.test(
  "is-future",
  "過去の日付は選択できません",
  (value) => {
    if (!value) return false;
    const now = dayjs();
    return dayjs(value).isAfter(now);
  }
);
// (例: 2000-8-1)
export const optionalYearDateSchema = yup
  .string()
  .test("year-number", "年は半角数字で入力してください", (value) => {
    if (!value) return true;
    const [year] = value.split("-").map(Number);
    if (!year) return false;
    return true;
  })
  .test("month-number", "月は半角数字で入力してください", (value) => {
    if (!value) return true;
    const [, month] = value.split("-").map(Number);
    if (!month) return false;
    return true;
  })
  .test("day-number", "日は半角数字で入力してください", (value) => {
    if (!value) return true;
    const [, , day] = value.split("-").map(Number);
    if (!day) return false;
    return true;
  })
  .test("is-valid-date", "値の形式が正しくありません", (value) => {
    if (!value) return true;
    // 入力値をDateオブジェクトとして解析
    const date = dayjs(value);

    // 解析された日付が有効でなければfalseを返す
    if (!date.isValid()) {
      return false;
    }

    // 文字列から年、月、日を取得
    const [year, month, day] = value.split("-").map(Number);

    // Dateオブジェクトの年月日が入力値と一致しない場合は、存在しない日付（例: 2月30日）
    if (
      date.year() !== year ||
      date.month() + 1 !== month ||
      date.date() !== day
    ) {
      return false;
    }

    // 1850年以降の日付かどうかをチェック
    if (year < 1850) {
      return false;
    }

    // 現在の日付より前であるかをチェック
    const today = dayjs();
    today.startOf("day"); // 時間を00:00:00.000に設定
    if (date >= today) {
      return false;
    }

    return true;
  });
