import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import ChipGroup from "components/molecules/ChipGroup";
import Sidebar from "components/organisms/TeacherSidebar";
import useMyRequestsPageApi from "./useApi";
import { kindOfTeacherRequestsType } from "./store";
import RequestList from "./organisms/RequestList";
import TitleBlock from "../../index/organisms/TitleBlock";

const MyRequests: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading, kindOfTeacherRequests, setKindOfTeacherRequests } =
    useMyRequestsPageApi();

  const RequestSelectLabelOptions: {
    label: string;
    value: kindOfTeacherRequestsType;
  }[] = [
    { label: "すべて", value: "only_request" },
    { label: "依頼中", value: "requesting" },
    { label: "承諾", value: "accepted" },
    { label: "辞退・キャンセル", value: "denied_or_canceled" }
  ];

  return (
    <CommonLayout>
      <Meta title="複業先生｜直接依頼" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Stack
            sx={{
              width: "100%"
            }}
            gap={2}
          >
            <TitleBlock
              title="直接依頼"
              description="学校からあなたへの授業依頼の一覧です。"
            />
            <ChipGroup
              options={RequestSelectLabelOptions}
              selectedValue={kindOfTeacherRequests}
              onSelect={setKindOfTeacherRequests}
            />
            <RequestList isLoading={isLoading} />
          </Stack>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyRequests;
