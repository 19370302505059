import useCurrentSchool from "hooks/useCurrentSchool";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { isLoggedInAtom } from "store/AuthStore";
import {
  schoolIsLoggedInAtom,
  currentSchoolAtom,
  prevLoginPathAtom
} from "store/SchoolAuthStore";

function SchoolPrivateRoute(props: any) {
  const { mutate } = useCurrentSchool();
  const isLoggedIn = useAtom(isLoggedInAtom)[0];
  const schoolIsLoggedIn = useAtom(schoolIsLoggedInAtom)[0];
  const currentSchool = useAtom(currentSchoolAtom)[0];
  const [, setPrevLoginPath] = useAtom(prevLoginPathAtom);

  if (isLoggedIn) {
    setPrevLoginPath(props.location.pathname);
    return <Redirect to="/schools/login" />;
  }

  if (!schoolIsLoggedIn) {
    setPrevLoginPath(props.location.pathname);
    return <Redirect to="/schools/login" />;
  }
  // 登録後はユーザー情報が変わるので、強制的に再取得する
  useEffect(() => {
    if (props.path === "/schools/profile/complete") {
      mutate();
    }
  }, [props.path, mutate]);

  if (
    !currentSchool.school_teacher_profile_id &&
    props.path !== "/schools/profile/to-new" &&
    props.path !== "/schools/profile/new" &&
    props.path !== "/schools/profile/confirm" &&
    props.path !== "/schools/profile/complete"
  ) {
    setPrevLoginPath(props.location.pathname);
    return <Redirect to="/schools/profile/to-new" />;
  }

  setPrevLoginPath("");
  return <Route {...props} />;
}

export default SchoolPrivateRoute;
