import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import reactStringReplace from "react-string-replace";

import { isLoggedInAtom, currentUserAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom, currentSchoolAtom } from "store/SchoolAuthStore";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import type React from "react";
import { Stack } from "@mui/material";
import Button from "components/atoms/mui/Button";
import { useDialog } from "hooks/useDialog";
import { fileType } from "types/fileType";
import FileContent from "components/atoms/FileContent";
import DeleteCheckModal from "./DeleteCheckModal";
import DownloadCheckModal from "./DownloadCheckModal";

type MessageBlockProps = {
  messageId?: number;
  sendUserId?: number;
  src: string;
  name: string;
  date: string;
  message: string;
  isDeleted?: boolean;
  file?: fileType;
  isOfficeNotification?: boolean;
  deleteRequestComments?: (commentId: string) => void;
};

const MessageBlock: React.FC<MessageBlockProps> = (props) => {
  const {
    src,
    name,
    date,
    message,
    isDeleted,
    messageId,
    sendUserId,
    isOfficeNotification,
    file,
    deleteRequestComments
  } = props;
  const theme = useTheme();
  const regExp = /(https?:\/\/\S+)/g;
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);

  const downloadDialog = useDialog();

  const deleteDialog = useDialog();

  const Text = (
    <Box sx={{ width: "100%" }}>
      {isOfficeNotification && (
        <Typography
          fontSize="0.8rem"
          color={theme.palette.sub.main}
          sx={{
            width: "fit-content",
            padding: "0.2rem 0.7rem",
            border: `1px solid ${theme.palette.sub.main}`
          }}
        >
          事務局に向けたメッセージ
        </Typography>
      )}
      {isDeleted ? (
        <Typography
          sx={{
            color: theme.palette.sub.main,
            fontStyle: "italic"
          }}
        >
          このメッセージは削除されました
        </Typography>
      ) : (
        <Typography
          sx={{
            whiteSpace: "pre-wrap"
          }}
        >
          {reactStringReplace(message, regExp, (match) => (
            <a href={match} target="_blank" rel="noreferrer">
              {match}
            </a>
          ))}
        </Typography>
      )}
    </Box>
  );

  const File = (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ whiteSpace: "pre-wrap" }}>
        ファイルをアップロードしました。
      </Typography>
      {file && <FileContent file={file} />}
      <Box
        sx={{
          backgroundColor: theme.palette.link?.light,
          padding: "0.5rem",
          margin: "0.5rem 0"
        }}
      >
        <Button
          variant="text"
          color="secondary"
          onClick={downloadDialog.onOpen}
          startIcon={
            <InsertDriveFileOutlinedIcon
              sx={{ color: theme.palette.link?.main }}
            />
          }
          sx={{
            display: "inline-flex",
            gap: "0.5rem",
            padding: "0.5rem",
            wordBreak: "break-all"
          }}
        >
          {file?.name}
        </Button>
        <Typography component="span">をダウンロード</Typography>
      </Box>
    </Box>
  );

  const deletable = () => {
    if (!sendUserId) return false;
    if (isDeleted) return false;
    if (!messageId) return false;

    if (isLoggedIn) {
      return currentUser.id === sendUserId;
    }
    if (schoolIsLoggedIn) {
      return currentSchool.id === sendUserId;
    }
    return false;
  };

  return (
    <Stack py={2} gap={0.5}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Stack direction="row" alignItems="center">
          <Avatar
            alt={name}
            src={src}
            sx={{
              width: "24px",
              height: "24px",
              marginRight: "4px"
            }}
          />
          <Typography fontWeight="bold">{name}</Typography>
        </Stack>
        <Typography color={theme.palette.sub.main}>{date}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        {file?.url ? File : Text}
        {deletable() && (
          <Box sx={{ textAlign: "end" }}>
            <Button
              color="inherit"
              variant="text"
              size="small"
              startIcon={<DeleteForever />}
              onClick={deleteDialog.onOpen}
              sx={{ px: 0, py: 0.5 }}
            >
              削除する
            </Button>
          </Box>
        )}
      </Box>
      {file && (
        <DownloadCheckModal
          isOpen={downloadDialog.isOpen}
          onClose={downloadDialog.onClose}
          file={file}
        />
      )}
      <DeleteCheckModal
        isOpen={deleteDialog.isOpen}
        onClose={deleteDialog.onClose}
        messageId={messageId || 0}
        deleteRequestComments={deleteRequestComments}
      />
    </Stack>
  );
};

export default MessageBlock;
