import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import ChipGroup from "components/molecules/ChipGroup";
import Sidebar from "components/organisms/TeacherSidebar";
import { kindOfTeacherRecruitmentsType } from "./store";
import useMyRecruitmentsPageApi from "./useApi";
import TitleBlock from "../../index/organisms/TitleBlock";
import RecruitmentList from "./organisms/RecruitmentList";

const MyRecruitments: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const RequestSelectLabelOptions: {
    label: string;
    value: kindOfTeacherRecruitmentsType;
  }[] = [
    { label: "すべて", value: "" },
    { label: "結果待ち", value: "waiting_for_result" },
    { label: "採用", value: "adopted" },
    { label: "お見送り・キャンセル", value: "denied_or_canceled" }
  ];

  const { isLoading, kindOfRecruitments, setKindOfRecruitments } =
    useMyRecruitmentsPageApi();

  return (
    <CommonLayout>
      <Meta title="複業先生｜応募済み" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Stack spacing={2} width="100%">
            <TitleBlock
              title="応募済みの授業募集"
              description="あなたの応募した授業が一覧で確認できます。"
            />
            <ChipGroup
              options={RequestSelectLabelOptions}
              selectedValue={kindOfRecruitments}
              onSelect={setKindOfRecruitments}
            />
            <RecruitmentList isLoading={isLoading} />
          </Stack>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyRecruitments;
