import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextField from "components/molecules/FormTextField";

import EmailchangeLink from "features/schools/profile/edit/molecules/EmailchangeLink";
import PasswordChangeLink from "features/schools/profile/edit/molecules/PasswordchangeLink";

import type React from "react";

import { mailMagazineOptions } from "constants/selectOptions";

type EditSectionProps = {
  editSectionRef: React.RefObject<HTMLDivElement>;
};

const SchoolTeacherInfoCard: React.FC<EditSectionProps> = ({
  editSectionRef
}) => (
  <Card
    sx={{
      borderRadius: 0,
      margin: "1rem auto"
    }}
    elevation={0}
    ref={editSectionRef}
  >
    <CardContent
      sx={{
        margin: ".5rem 0",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography variant="h3">登録者情報</Typography>
      <FormTextField
        name="manage_grade"
        label="担当学年"
        placeholder="例：1年"
      />
      <FormTextField
        name="manage_subject"
        label="担当教科"
        placeholder="例：現代文"
      />
      <FormTextField
        name="school_job"
        label="校務分掌"
        placeholder="例：生徒指導"
      />
      <FormRadioGroup
        name="is_mail_magazine"
        label="メールマガジンの配信"
        options={mailMagazineOptions}
        isRequired
      />
      <EmailchangeLink />
      <PasswordChangeLink />
    </CardContent>
  </Card>
);

export default SchoolTeacherInfoCard;
