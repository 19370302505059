import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import { masterSchoolType } from "types/masterDataTypes";
import { useDialog } from "hooks/useDialog";
import FormLabel from "components/atoms/FormLabel";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "components/atoms/fields/TextField";
import Button from "components/atoms/mui/Button";
import SelectSchoolModal from "../organisms/SelectSchoolModal";

type ProfileContentProps = {
  name: string;
  codeName: string;
  label: string;
  isRequired?: boolean;
  isEdit?: boolean;
  schoolDivision: "high_school" | "university";
};

// TODO: 汎用性を広げて、共通のmoluculesにする
const OriginSchoolField: React.FC<ProfileContentProps> = (props) => {
  const { name, codeName, label, isRequired, schoolDivision } = props;
  const selectModal = useDialog();
  const [isException, setIsException] = useState(false);

  const theme = useTheme();
  const { watch, setValue, control } = useFormContext();
  const schoolName = watch(name);

  const handleSelect = (value: masterSchoolType) => {
    setValue(codeName, value.school_code, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue(name, value.name, {
      shouldValidate: true,
      shouldDirty: true
    });
    selectModal.onClose();
  };

  const handleOriginSchoolRemove = () => {
    setValue(codeName, "", {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue(name, "", {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <FormLabel labelName={label} isRequired={isRequired} />
      {isException ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              placeholder={
                schoolDivision === "high_school"
                  ? "例：都立〇〇高等学校"
                  : "例：国立〇〇大学"
              }
            />
          )}
        />
      ) : (
        <>
          {schoolName ? (
            <Typography fontSize="1.1rem" fontWeight="bold">
              {schoolName}
            </Typography>
          ) : (
            <Typography color={theme.palette.sub.main}>
              {schoolDivision === "high_school"
                ? "出身高校を選択してください"
                : "出身大学を選択してください"}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={selectModal.onOpen}
            sx={{ height: "3rem" }}
            disableElevation
            startIcon={<AddIcon />}
          >
            {schoolDivision === "high_school"
              ? "出身高校を選択"
              : "出身大学を選択"}
          </Button>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "right"
        }}
      >
        {schoolName && (
          <Button
            color="error"
            variant="text"
            onClick={handleOriginSchoolRemove}
          >
            {schoolDivision === "high_school"
              ? "出身高校を削除"
              : "出身大学を削除"}
          </Button>
        )}
        <Button
          color="secondary"
          variant="text"
          onClick={() => {
            setValue(codeName, "", {
              shouldValidate: true,
              shouldDirty: true
            });
            setIsException(!isException);
          }}
          disableElevation
        >
          {isException ? "学校を選択する" : "学校が見つからない場合はこちら"}
        </Button>
      </Box>

      <SelectSchoolModal
        isOpen={selectModal.isOpen}
        onClose={selectModal.onClose}
        handleSelect={handleSelect}
        schoolDivision={schoolDivision}
      />
    </Box>
  );
};

export default OriginSchoolField;
