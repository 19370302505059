import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import { kindOfTeacherRequestsAtom, teacherRequestsAtom } from "./store";

const useMyRequestsPageApi = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const teacherId = currentUser.id.toString();
  const [kindOfTeacherRequests, setKindOfTeacherRequests] = useAtom(
    kindOfTeacherRequestsAtom
  );
  const [, setTeacherRequests] = useAtom(teacherRequestsAtom);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId)
      .lesson_requests,
    {
      query: { status: kindOfTeacherRequests },
      onSuccess: (data) => {
        setTeacherRequests(data);
      },
      onError: () => {
        addErrorMessage("依頼一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfTeacherRequests, setKindOfTeacherRequests };
};

export default useMyRequestsPageApi;
