import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { kindOfSchoolLessonsAtom, schoolLessonsAtom } from "./store";

const useMyLessonsPageApi = () => {
  const currentSchool = useAtomValue(currentSchoolAtom);
  const [, setSchoolLessons] = useAtom(schoolLessonsAtom);
  const [kindOfSchoolLessons, setKindOfSchoolLessons] = useAtom(
    kindOfSchoolLessonsAtom
  );

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lessons,
    {
      query: { status: kindOfSchoolLessons },
      onSuccess: (data) => {
        setSchoolLessons(data);
      },
      onError: () => {
        addErrorMessage("授業一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfSchoolLessons, setKindOfSchoolLessons };
};

export default useMyLessonsPageApi;
